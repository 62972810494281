import React from 'react';
import './ImageCard.css'; // For styling

const ImageCard = ({ imageUrl, title, description, gitLink }) => {
    return (
        <div className="card">
            <img src={imageUrl} alt={title} className="card-image" />
            <h2 className="card-title">{title}</h2>
            <p className="card-description">{description}</p>
            <a href={gitLink} className="card-link">
                <img src="https://cdn.iconscout.com/icon/free/png-256/github-153-675523.png" alt="Github" className="git-icon" />
            </a>
        </div>
    );
};

export default ImageCard;
