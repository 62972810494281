import React, { useCallback } from 'react';
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import './App.css';
import particlesOptions from "./particles.json";
import ImageCard from './components/ImageCard';
import { Helmet } from 'react-helmet';


// Images
import vitalityLogo from './logos/images/vitality.png';
import lyncLogo from './logos/images/lync.png';
import KG from './logos/images/KG.png';
import html from './logos/html5.svg';
import java from './logos/java.png';
import linux from './logos/linux.svg';
import js from './logos/square-js.svg';
import python from './logos/python.svg';
import css from './logos/css3-alt.svg';
import C from './logos/c-solid.svg';
import tf from './logos/TF.png';
import cpp from './logos/cpp.svg';
import arm from './logos/arm.svg';
import firebase from './logos/firebase.png';
import flutter from './logos/flutter.png';


function App() {
    const particlesInit = useCallback(main => {
        loadFull(main);
    }, [])

    return (
        <div className="App">
            <Particles options={particlesOptions} init={particlesInit}/>
            <link rel="icon" type="image/png" href={KG} sizes="16x16" />
            <Helmet>
                <title>Kartikeya Gullapalli</title>
                <link rel="apple-touch-icon" sizes="180x180" href="logos/favicons/apple-touch-icon.png"/>
                <link rel="icon" type="image/png" sizes="32x32" href="logos/favicons/favicon-32x32.png"/>
                <link rel="icon" type="image/png" sizes="16x16" href="logos/favicons/favicon-16x16.png"/>
                <link rel="manifest" href="logos/favicons/site.webmanifest"/>
                <link rel="mask-icon" href="logos/favicons/safari-pinned-tab.svg" color="#5bbad5"/>
            </Helmet>
            
            <meta name="msapplication-TileColor" content="#da532c"/>
            <meta name="theme-color" content="#ffffff"></meta>

            <header className="App-header">
                <div className="name">
                    <h1>Kartikeya<br/> Gullapalli</h1>
                </div>
            </header>

            <div className="about">
            <h1 id="about-header">About Me</h1>
            
            <div class="grid-container">
                <div class="grid-item">
                    <img class="image-circle" src="https://media.licdn.com/dms/image/C4E03AQGSYz8lPhs_kw/profile-displayphoto-shrink_800_800/0/1626285694603?e=2147483647&v=beta&t=VkCt0f8CcFFapc-W7EmALMjybzBnOhRqoIpDayxIg50" alt="Kartikeya Gullapalli"/>
                </div>
                <div class="grid-item about-description">
                    <p>
                    I'm Kartikeya Gullapalli, an Electrical and Computer Engineer from The University of Texas at Austin with a rich background spanning from machine learning research at the University of Houston to chatbot development for the Global Clash Cup. My technical prowess in platforms like Python, C, and Assembly has been instrumental in leading my VEX Robotics team to the World Championship and innovating with Longhorn Racing Solar and Texas Aerial Robotics. Beyond this, my role in the startup Dojoko highlighted my expertise in integrating technology with business, a realm I'm particularly passionate about. Additionally, my flair for content creation allows me to articulate and share the complexities and innovations of the tech-business nexus. As an engineer, visionary, and storyteller, I'm driven by the endless possibilities that emerge when technology meets business.
                    </p>
                </div>
            </div>

            </div>


            <div className="skills">
            <h1 id="skills-title">Skills</h1>
            <div class="carousel">
                    <div class="moving-logos">

                        <img src={python} alt="Python"/>
                        <img src={js} alt="JS"/>
                        <img src="https://assets.stickpng.com/images/5847f981cef1014c0b5e48be.png" alt="Git"/>
                        <img src={tf} alt="Tensorflow"/>
                        <img src={html} alt="HTML"/>
                        <img src={css} alt="CSS"/>
                        <img src={flutter} alt="Flutter"/>
                        <img src={firebase} alt="Firebase"/>
                        <img src={C} alt="C"/>
                        <img src={arm} alt="Assembly"/>
                        <img src={cpp} alt="C++"/>
                        <img src={java} alt="Java"/>
                        <img src={linux} alt="Linux"/>

                        <img src={python} alt="Python"/>
                        <img src={js} alt="JS"/>
                        <img src="https://assets.stickpng.com/images/5847f981cef1014c0b5e48be.png" alt="Git"/>
                        <img src={tf} alt="Tensorflow"/>
                        <img src={html} alt="HTML"/>
                        <img src={css} alt="CSS"/>
                        <img src={flutter} alt="Flutter"/>
                        <img src={firebase} alt="Firebase"/>
                        <img src={C} alt="C"/>
                        <img src={arm} alt="Assembly"/>
                        <img src={cpp} alt="C++"/>
                        <img src={java} alt="Java"/>
                        <img src={linux} alt="Linux"/>
                    </div>
                </div>
               
            </div>

            <div className="projects">
            <h1>Projects</h1>
            <div class="project-cards">
                <ImageCard 
                    imageUrl={vitalityLogo}
                    title="Vitality"
                    description="Vitality is a versatile Discord bot adopted by over 100 servers, enhancing the user experience for more than 10,000 members with its multipurpose features."
                    gitLink="https://github.com/Kartikinator/Vitality"
                    class="project-card project-1"
                />
                <ImageCard 
                    imageUrl={lyncLogo}
                    title="lync"
                    description="lync is a dynamic sports app designed to connect players with local enthusiasts, facilitating meet-ups and ensuring you never miss a game in your community."
                    gitLink="https://github.com/Kartikinator/lync"
                    class="project-card project-2"
                />
                <ImageCard 
                    imageUrl={KG}
                    title="Personal Website"
                    description="Welcome to my personal corner on the web! You're currently on my website, where I showcase my experiences, skills, and the projects that fuel my passion."
                    gitLink="https://github.com/Kartikinator/Personal-Website"
                    class="project-card project-3"
                />
            </div>
           
            </div>

            <footer>
                <div class="footer-content">
                    <h2>Contact Me</h2>
                    <form action="/submit-contact" method="post" class="contact-form">
                        <div class="form-group">
                            <label for="name">Name:</label>
                            <input type="text" id="name" name="name" required/>
                        </div>
                        
                        <div class="form-group">
                            <label for="email">Email:</label>
                            <input type="email" id="email" name="email" required/>
                        </div>
                        
                        <div class="form-group">
                            <label for="message">Message:</label>
                            <textarea id="message" name="message" rows="4" required></textarea>
                        </div>
                        
                        <button type="submit">Submit</button>
                    </form>
                </div>
            </footer>
        </div>
    );
    
}

export default App;
